import Cookies from "js-cookie";
import { useState } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Order from "../Order/Order";

const Orders = ({ orders, loading, page, setPage, pay }) => {
  const [payLoading, setPayLoading] = useState(false);
  let history = useHistory();
  const token = Cookies.get("token");
  const handlePay = (e) => {
    setPayLoading(true);
    e.preventDefault();
    let requestBody = [];
    for (let event of e.target) {
      if (event.checked) {
        requestBody.push(event.id);
      }
    }
    fetch(
      `https://api.pavelandco.com/api/admin/pay/${process.env.REACT_APP_ADMIN_HASH}`,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `jwt ${token}`,
        },
        body: JSON.stringify(requestBody),
      }
    )
      .then((response) => {
        if (response.ok) {
          history.go();
          return response.json();
        }
        throw response;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setPayLoading(false);
      });
  };
  return (
    <Container>
      <Row className="mt-5">
        {loading ? (
          <h1>
            Loading Orders <Spinner animation="border" variant="dark" />
          </h1>
        ) : (
          <>
            <Row className="d-flex justify-content-center mb-3">
              <Col className="d-flex justify-content-center">
                <Button onClick={() => setPage(page - 1)} disabled={page === 0}>
                  -
                </Button>

                <h2 className="mx-3">{page + 1}</h2>

                <Button onClick={() => setPage(page + 1)}>+</Button>
              </Col>
            </Row>

            {pay && (
              <Row>
                <Col className="d-flex justify-content-center mb-3">
                  <h3>
                    Total:{" "}
                    {orders.reduce((prev, cur) => {
                      return (
                        prev +
                        (cur.type === "tg_spam"
                          ? cur.amount * 1.65
                          : cur.amount)
                      );
                    }, 0)}{" "}
                    RUB
                  </h3>
                </Col>
              </Row>
            )}
            <form onSubmit={(e) => handlePay(e)}>
              <Container className="px-0">
                <Row>
                  {orders
                    .sort((a, b) => {
                      if (a.dateOrdered > b.dateOrdered) return -1;
                      else return 1;
                    })
                    .map((order) => {
                      return <Order order={order} key={order._id} pay={pay} />;
                    })}
                </Row>
                {pay && (
                  <Row className="mx-0">
                    <Col className="d-flex justify-content-center mb-3  ">
                      <Button
                        className="orderDetailsButton"
                        type="submit"
                        disabled={payLoading}
                      >
                        Pay{" "}
                        {payLoading && (
                          <Spinner
                            size="sm"
                            animation="border"
                            variant="light"
                          />
                        )}
                      </Button>
                    </Col>
                  </Row>
                )}
              </Container>
            </form>
          </>
        )}
      </Row>
    </Container>
  );
};

export default Orders;
