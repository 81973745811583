import axios from "axios";
import Cookies from "js-cookie";
import { useState } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  Button,
  Form,
  FloatingLabel,
  Spinner,
} from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "./Login.css";

const Login = () => {
  const history = useHistory();
  if (Cookies.get("token")) {
    history.push("/");
  }

  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState(<></>);

  const handleLogin = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const adminResponse = await axios.post(
        "https://api.pavelandco.com/api/admin/login",
        {
          login: event.target.login.value,
          password: event.target.password.value,
        }
      );
      Cookies.set("token", adminResponse.data.token);
      Cookies.set("role", adminResponse.data.role);
      Cookies.set("login", adminResponse.data.login);
      history.go();
    } catch (err) {
      if (err.response.status === 422) {
        setLoginError(<span className="errMessage">Admin Not Found</span>);
      } else if (err.response.status === 400) {
        setLoginError(<span className="errMessage">Incorrect Password</span>);
      } else {
        setLoginError(<span className="errMessage">Unknown Error</span>);
      }
      console.log(loginError);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Container>
        <Row>
          <Col className="loginCol">
            <Card className="loginCard">
              <Card.Body>
                <Card.Img
                  variant="top"
                  src={process.env.PUBLIC_URL + "/brand_logo.png"}
                  className="loginLogo"
                />
                <Form onSubmit={handleLogin} className="loginCardBody">
                  {loginError}
                  <FloatingLabel
                    controlId="login"
                    label="Admin ID"
                    className="loginLabel"
                  >
                    <Form.Control type="text" />
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="password"
                    label="Password"
                    className="loginLabel"
                  >
                    <Form.Control type="password" />
                  </FloatingLabel>
                  <Button
                    className="defaultButton"
                    variant="primary"
                    disabled={loading}
                    type="submit"
                  >
                    Login{" "}
                    {loading && (
                      <Spinner size="sm" animation="border" variant="light" />
                    )}
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Login;
