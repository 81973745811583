import "./App.css";
import { Route, Switch, Redirect } from "react-router";
import Cookies from "js-cookie";
import Login from "./Components /Login/Login";
import Admin from "./Components /Admin/Admin";

function App() {
  const session = Cookies.get("token") ? true : false;
  return (
    <div className="App">
      <Switch>
        <Route path="/login" render={(props) => <Login />} />
        <Route
          path="/"
          render={(props) => (session ? <Admin /> : <Redirect to="/login" />)}
        />
      </Switch>
    </div>
  );
}

export default App;
