import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import Header from "../Header/Header";
import Orders from "../Orders/Orders";
import PayOut from "../PayOut/PayOut";
import Expenses from "../Expenses/Expenses";
import OrderInfo from "../OrderInfo/OrderInfo";
import UserInfo from "../UserInfo/UserInfo";

const Admin = () => {
  const [orders, setOrders] = useState({});
  const [loading, setLoading] = useState(true);
  const [unpaidCount, setUnpaidCount] = useState();
  const [unpaidOrders, setUnpaidOrders] = useState(null);
  const [page, setPage] = useState(0);
  const [isReferral, setIsReferral] = useState(false);
  useEffect(() => {
    const token = Cookies.get("token");
    const login = Cookies.get("login");
    fetch(
      `https://api.pavelandco.com/api/admin/orders/${process.env.REACT_APP_ADMIN_HASH}?page=${page}&login=${login}`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `jwt ${token}`,
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 403) {
          setIsReferral(true);
        }
        throw response;
      })
      .then((orders) => {
        setOrders(orders);

        setUnpaidOrders(
          orders.filter(
            (order) =>
              order.paid === false &&
              (order.type === "tg_spam" ||
                order.type === "tg_inviting" ||
                order.type === "tg_groupSpam") &&
              order.status !== "Awaiting Payment" &&
              order.status !== "Awaiting Details"
          )
        );
        setUnpaidCount(
          orders.filter(
            (order) =>
              order.paid === false &&
              (order.type === "tg_spam" ||
                order.type === "tg_inviting" ||
                order.type === "tg_groupSpam") &&
              order.status !== "Awaiting Payment" &&
              order.status !== "Awaiting Details"
          ).length
        );
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [page]);
  return (
    <>
      <Header loading={loading} unpaidCount={unpaidCount} />
      <Switch>
        {!isReferral && (
          <>
            <Route
              path="/orders"
              render={(props) => (
                <Orders
                  orders={orders}
                  loading={loading}
                  page={page}
                  setPage={setPage}
                />
              )}
            />
            <Route
              render={(props) => <OrderInfo props={props} />}
              path={"/order/:id"}
            />
            <Route
              render={(props) => <UserInfo props={props} />}
              path={"/user/:id"}
            />
            <Route
              path="/pay"
              render={(props) => (
                <Orders
                  orders={unpaidOrders}
                  loading={loading}
                  page={page}
                  setPage={setPage}
                  pay={true}
                />
              )}
            />
            <Route
              path="/expenses"
              render={(props) => (
                <Expenses
                  orders={unpaidOrders}
                  loading={loading}
                  page={page}
                  setPage={setPage}
                  pay={true}
                />
              )}
            />
            <Route exact path="/" render={(props) => <PayOut />} />
          </>
        )}
        <Route path="/" render={(props) => <PayOut />} />
      </Switch>
    </>
  );
};

export default Admin;
