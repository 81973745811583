import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import "./DetailsModal.css";

const DetailsModal = ({ language, order, handleClose, dateFormat }) => {
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          {(() => {
            switch (order.type) {
              case "tg_spam":
                return (
                  <>
                    <Row className="mb-2">
                      <Col
                        md={language === "RU" ? 4 : 3}
                        lg={language === "RU" ? 3 : 2}
                      >
                        <span className="orderDetailTitle">Text Files:</span>
                      </Col>
                      <Col className="scrollableOrderInfo">
                        <span className="orderDetailInfo">
                          {" "}
                          {order.tg_spam.textFiles.map((file, index) => {
                            return <li>{file}</li>;
                          })}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col
                        md={language === "RU" ? 4 : 3}
                        lg={language === "RU" ? 3 : 2}
                      >
                        <span className="orderDetailTitle">Media Files:</span>
                      </Col>
                      <Col className="scrollableOrderInfo">
                        <span className="orderDetailInfo">
                          {order.tg_spam.mediaFiles.map((file, index) => {
                            return <li>{file}</li>;
                          })}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col
                        md={language === "RU" ? 4 : 3}
                        lg={language === "RU" ? 3 : 2}
                      >
                        {" "}
                        <span className="orderDetailTitle">Users:</span>
                      </Col>
                      <Col className="scrollableOrderInfo">
                        <span className="orderDetailInfo">
                          {order.tg_spam.usersList.map((file, index) => {
                            return <li>{file}</li>;
                          })}
                        </span>
                      </Col>
                    </Row>
                  </>
                );
              case "tg_groupSpam":
                return (
                  <>
                    <Row className="mb-2">
                      <Col md={language === "RU" ? 4 : 3}>
                        <span className="orderDetailTitle">Text Files:</span>
                      </Col>
                      <Col className="scrollableOrderInfo">
                        <span className="orderDetailInfo">
                          {order.tg_groupSpam.textFiles.map((file, index) => {
                            return <li>{file}</li>;
                          })}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={language === "RU" ? 4 : 3}>
                        <span className="orderDetailTitle">Media Files:</span>
                      </Col>
                      <Col className="scrollableOrderInfo">
                        {" "}
                        <span className="orderDetailInfo">
                          {order.tg_groupSpam.mediaFiles.map((file, index) => {
                            return <li>{file}</li>;
                          })}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={language === "RU" ? 4 : 3}>
                        <span className="orderDetailTitle">Groups List:</span>
                      </Col>
                      <Col className="scrollableOrderInfo">
                        {" "}
                        <span className="orderDetailInfo">
                          {order.tg_groupSpam.groupList.map((file, index) => {
                            return <li>{file}</li>;
                          })}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={language === "RU" ? 4 : 3}>
                        <span className="orderDetailTitle">Times Per Day:</span>
                      </Col>
                      <Col className="scrollableOrderInfo">
                        {" "}
                        <span className="orderDetailInfo">
                          {order.tg_groupSpam.timesPerDay}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={language === "RU" ? 4 : 3}>
                        <span className="orderDetailTitle">Days:</span>
                      </Col>
                      <Col className="scrollableOrderInfo">
                        {" "}
                        <span className="orderDetailInfo">
                          {order.tg_groupSpam.days}
                        </span>
                      </Col>
                    </Row>
                  </>
                );
              case "tg_inviting":
                return (
                  <>
                    <Row className="mb-2">
                      <Col md={3} lg={2}>
                        {" "}
                        <span className="orderDetailTitle">Group To:</span>
                      </Col>
                      <Col className="scrollableOrderInfo">
                        {" "}
                        <span className="orderDetailInfo">
                          {order.tg_inviting.groupTo}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={3} lg={2}>
                        {" "}
                        <span className="orderDetailTitle">Groups From:</span>
                      </Col>
                      <Col className="scrollableOrderInfo">
                        {" "}
                        <span className="orderDetailInfo">
                          {order.tg_inviting.groupsFrom.map((file, index) => {
                            return <li>{file}</li>;
                          })}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={3} lg={2}>
                        {" "}
                        <span className="orderDetailTitle">
                          Users List From:
                        </span>
                      </Col>
                      <Col className="scrollableOrderInfo">
                        {" "}
                        <span className="orderDetailInfo">
                          {order.tg_inviting.usersListFrom.map(
                            (file, index) => {
                              return <li>{file}</li>;
                            }
                          )}
                        </span>
                      </Col>
                    </Row>
                  </>
                );
              case "tg_views":
                return (
                  <>
                    <Row className="mb-2">
                      <Col md={3}>
                        <span className="orderDetailTitle">Channel:</span>
                      </Col>
                      <Col className="scrollableOrderInfo">
                        {" "}
                        <span className="orderDetailInfo">
                          {order.tg_views.channel}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={3}>
                        <span className="orderDetailTitle">Start Time:</span>
                      </Col>
                      <Col className="scrollableOrderInfo">
                        {" "}
                        <span className="orderDetailInfo">
                          {dateFormat(order.tg_views.startTime)}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={3}>
                        <span className="orderDetailTitle">Days:</span>
                      </Col>
                      <Col className="scrollableOrderInfo">
                        {" "}
                        <span className="orderDetailInfo">
                          {order.tg_views.daysCount}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={3}>
                        <span className="orderDetailTitle">
                          Min Views Per Hour:
                        </span>
                      </Col>
                      <Col className="scrollableOrderInfo">
                        {" "}
                        <span className="orderDetailInfo">
                          {order.tg_views.minViewsPerHour}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={3}>
                        <span className="orderDetailTitle">
                          Max Views Per Hour:
                        </span>
                      </Col>
                      <Col className="scrollableOrderInfo">
                        {" "}
                        <span className="orderDetailInfo">
                          {order.tg_views.maxViewsPerHour}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={3}>
                        <span className="orderDetailTitle">Count Left:</span>
                      </Col>
                      <Col className="scrollableOrderInfo">
                        {" "}
                        <span className="orderDetailInfo">
                          {"-"}
                          {order.tg_views.postViewsCountLeft}
                          {" %"}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={3}>
                        <span className="orderDetailTitle">Count Right:</span>
                      </Col>
                      <Col className="scrollableOrderInfo">
                        {" "}
                        <span className="orderDetailInfo">
                          {"+"}
                          {order.tg_views.postViewsCountRight}
                          {" %"}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={3}>
                        <span className="orderDetailTitle">Country:</span>
                      </Col>
                      <Col className="scrollableOrderInfo">
                        {" "}
                        <span className="orderDetailInfo">
                          {(() => {
                            switch (order.tg_views.country) {
                              case 1:
                                return "RU";
                              case 2:
                                return "EN";
                              case 0:
                                return "ALL";
                              case 16:
                                return "CIS";
                              default:
                                return "Error";
                            }
                          })()}
                        </span>
                      </Col>
                    </Row>
                  </>
                );
              case "tg_bots":
                return (
                  <>
                    <Row className="mb-2">
                      <Col md={3} lg={2}>
                        <span className="orderDetailTitle">Link:</span>
                      </Col>
                      <Col className="scrollableOrderInfo">
                        {" "}
                        <span className="orderDetailInfo">
                          {order.tg_bots.link}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={3} lg={2}>
                        <span className="orderDetailTitle">Hours:</span>
                      </Col>
                      <Col className="scrollableOrderInfo">
                        {" "}
                        <span className="orderDetailInfo">
                          {order.tg_bots.hours}
                        </span>
                      </Col>
                    </Row>
                  </>
                );
              case "wa_spam":
                return (
                  <>
                    <Row className="mb-2">
                      <Col md={3} lg={2}>
                        <span className="orderDetailTitle">Text Files</span>
                      </Col>
                      <Col className="scrollableOrderInfo">
                        {" "}
                        <span
                          className="orderDetailInfo"
                          // onClick={() =>
                          //   (document.getElementById(
                          //     "iframeDisplay"
                          //   ).innerHTML =
                          //     '<iframe src="../HtmlPage1.html" height="200" width="300" ></iframe>')
                          // }
                        >
                          {order.wa_spam.textFiles.map((file, index) => {
                            if (
                              index !== order.wa_spam.textFiles.length - 1 &&
                              order.wa_spam.textFiles.length > 1
                            )
                              return file + ", ";
                            else return file;
                          })}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={3} lg={2}>
                        <span className="orderDetailTitle">Numbers Files:</span>
                      </Col>
                      <Col className="scrollableOrderInfo">
                        {" "}
                        <span className="orderDetailInfo">
                          {order.wa_spam.numbersFiles.map((file, index) => {
                            if (
                              index !== order.wa_spam.numbersFiles.length - 1 &&
                              order.wa_spam.numbersFiles.length > 1
                            )
                              return file + ", ";
                            else return file;
                          })}
                        </span>
                      </Col>
                    </Row>
                  </>
                );
              case "wa_spam_with_image":
                return (
                  <>
                    <Row className="mb-2">
                      <Col md={3} lg={2}>
                        <span className="orderDetailTitle">Text Files:</span>
                      </Col>
                      <Col className="scrollableOrderInfo">
                        {" "}
                        <span className="orderDetailInfo">
                          {order.wa_spam_with_image.textFiles.map(
                            (file, index) => {
                              if (
                                index !==
                                  order.wa_spam_with_image.textFiles.length -
                                    1 &&
                                order.wa_spam_with_image.textFiles.length > 1
                              )
                                return file + ", ";
                              else return file;
                            }
                          )}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={3} lg={2}>
                        <span className="orderDetailTitle">Numbers Files:</span>
                      </Col>
                      <Col className="scrollableOrderInfo">
                        {" "}
                        <span className="orderDetailInfo">
                          {order.wa_spam_with_image.numbersFiles.map(
                            (file, index) => {
                              if (
                                index !==
                                  order.wa_spam_with_image.numbersFiles.length -
                                    1 &&
                                order.wa_spam_with_image.numbersFiles.length > 1
                              )
                                return file + ", ";
                              else return file;
                            }
                          )}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={3} lg={2}>
                        <span className="orderDetailTitle">Images File:</span>
                      </Col>
                      <Col className="scrollableOrderInfo">
                        {" "}
                        <span className="orderDetailInfo">
                          {order.wa_spam_with_image.imagesFile.map(
                            (file, index) => {
                              if (
                                index !==
                                  order.wa_spam_with_image.imagesFile.length -
                                    1 &&
                                order.wa_spam_with_image.imagesFile.length > 1
                              )
                                return file + ", ";
                              else return file;
                            }
                          )}
                        </span>
                      </Col>
                    </Row>
                  </>
                );
              case "vi_spam":
                return (
                  <>
                    <Row className="mb-2">
                      <Col md={3} lg={2}>
                        <span className="orderDetailTitle">Text Files:</span>
                      </Col>
                      <Col className="scrollableOrderInfo">
                        {" "}
                        <span className="orderDetailInfo">
                          {order.vi_spam.textFiles.map((file, index) => {
                            if (
                              index !== order.vi_spam.textFiles.length - 1 &&
                              order.vi_spam.textFiles.length > 1
                            )
                              return file + ", ";
                            else return file;
                          })}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={3} lg={2}>
                        <span className="orderDetailTitle">Numbers Files:</span>
                      </Col>
                      <Col className="scrollableOrderInfo">
                        {" "}
                        <span className="orderDetailInfo">
                          {" "}
                          {order.vi_spam.numbersFiles.map((file, index) => {
                            if (
                              index !== order.vi_spam.numbersFiles.length - 1 &&
                              order.vi_spam.numbersFiles.length > 1
                            )
                              return file + ", ";
                            else return file;
                          })}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={3} lg={2}>
                        <span className="orderDetailTitle">Images File:</span>
                      </Col>
                      <Col className="scrollableOrderInfo">
                        {" "}
                        <span className="orderDetailInfo">
                          {" "}
                          {order.vi_spam.imagesFile.map((file, index) => {
                            if (
                              index !== order.vi_spam.imagesFile.length - 1 &&
                              order.vi_spam.imagesFile.length > 1
                            )
                              return file + ", ";
                            else return file;
                          })}
                        </span>
                      </Col>
                    </Row>
                  </>
                );
              case "tt":
                return (
                  <>
                    <Row className="mb-2">
                      <Col md={3} lg={2}>
                        <span className="orderDetailTitle">Type:</span>
                      </Col>
                      <Col className="scrollableOrderInfo">
                        {" "}
                        <span className="orderDetailInfo">
                          {order.tt.orderType}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={3} lg={2}>
                        <span className="orderDetailTitle">Hours:</span>
                      </Col>
                      <Col className="scrollableOrderInfo">
                        {" "}
                        <span className="orderDetailInfo">
                          {" "}
                          {order.tt.hours}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={3} lg={2}>
                        <span className="orderDetailTitle">Url:</span>
                      </Col>
                      <Col className="scrollableOrderInfo">
                        {" "}
                        <span className="orderDetailInfo"> {order.tt.url}</span>
                      </Col>
                    </Row>
                  </>
                );
              case "business_expenses":
                return (
                  <>
                    <Row className="mb-2">
                      <Col md={3} lg={2}>
                        <span className="orderDetailTitle">Expense Name:</span>
                      </Col>
                      <Col className="scrollableOrderInfo">
                        {" "}
                        <span className="orderDetailInfo">
                          {order.business_expenses.expenseName}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={3} lg={2}>
                        <span className="orderDetailTitle">Expense Type:</span>
                      </Col>
                      <Col className="scrollableOrderInfo">
                        {" "}
                        <span className="orderDetailInfo">
                          {order.business_expenses.expenseType}
                        </span>
                      </Col>
                    </Row>
                  </>
                );
              default:
                return <div>Error defining order type</div>;
            }
          })()}
          <Row className="mb-2">
            <Col md={3} lg={2}>
              <span className="orderDetailTitle">Comment:</span>
            </Col>
            <Col className="scrollableOrderInfo">
              {" "}
              <span className="orderDetailInfo"> {order.comment}</span>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </>
  );
};

export default DetailsModal;
