import Cookies from "js-cookie";
import { useState } from "react";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";

const Expenses = () => {
  const token = Cookies.get("token");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(0);
  const addExpense = (e) => {
    setLoading(true);
    setSuccess(0);
    e.preventDefault();
    fetch(
      `https://api.pavelandco.com/api/admin/expense/${process.env.REACT_APP_ADMIN_HASH}`,
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `jwt ${token}`,
        },
        body: JSON.stringify({
          business_expenses: {
            expenseName: e.target.expenseName.value,
            expenseType: e.target.expenseType.value,
          },
          amount: e.target.expenseAmount.value,
          comment: e.target.expenseComment.value,
          dateOrdered: e.target.dateOrdered.value,
          type: "business_expenses",
          userId: "Admin",
          priceRu: 1,
          paid: true,
        }),
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        setSuccess(1);
        console.log(data);
      })
      .catch((err) => {
        setSuccess(-1);
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Container className="mt-4">
        <Row>
          <Col xs={12} md={4}>
            <Row>
              <Col>
                <h2>Add New Expenses</h2>
              </Col>
            </Row>

            <Form onSubmit={(e) => addExpense(e)}>
              <Row>
                <Col>
                  <FloatingLabel
                    controlId="expenseType"
                    label="Type"
                    className="mb-3"
                  >
                    <Form.Select>
                      <option value="promo">Promotion</option>
                      <option value="spec">Specialists</option>
                      <option value="salary">Salary</option>
                      <option value="other">Other</option>
                    </Form.Select>
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="expenseName"
                    label="Name"
                    className="mb-3"
                  >
                    <Form.Control
                      required
                      type="text"
                      placeholder="Expense Name"
                    />
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="expenseAmount"
                    label="Amount"
                    className="mb-3"
                  >
                    <Form.Control
                      type="number"
                      placeholder="15000"
                      required
                      min="0"
                    />
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="dateOrdered"
                    label="Date"
                    className="mb-3"
                  >
                    <Form.Control type="date" name="date2" required />
                  </FloatingLabel>
                  <FloatingLabel controlId="expenseComment" label="Comment">
                    <Form.Control type="text" placeholder="Comment" />
                  </FloatingLabel>
                  <div className="d-flex justify-content-center">
                    <Button
                      type="submit"
                      className="calculateButton mt-2"
                      disabled={loading}
                    >
                      Submit{" "}
                      {loading && (
                        <Spinner size="sm" animation="border" variant="light" />
                      )}
                    </Button>
                  </div>
                  <div className="d-flex justify-content-center">
                    {success !== 0 &&
                      (success > 0 ? "Success!" : "Unsuccessfully")}
                  </div>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Expenses;
