import { useState } from "react";
import { Button, Col, Container, FormCheck, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import DetailsModal from "../DetailsModal/DetailsModal";
import { dateFormat } from "../../dateFormat";
import "./Order.css";

const Order = ({ order, pay }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Col xs={12} md={4}>
        <div className="singleOrder">
          <Container className="pt-2 ps-4">
            <Row>
              <Col className="d-flex justify-content-between">
                <div>
                  <span className="orderDetailTitle">ID: </span>
                  <Link to={`order/${order._id}`} className="orderId">
                    <span>{order._id}</span>
                  </Link>
                </div>{" "}
                {pay && <FormCheck className="payOrderCheck" id={order._id} />}
              </Col>
            </Row>
            <Row>
              <Col>
                <span className="orderDetailTitle">Type: </span>
                <span>{order.type}</span>
              </Col>
            </Row>
            <Row>
              <Col>
                <span className="orderDetailTitle">Amount: </span>
                <span>{order.amount}</span>
              </Col>
            </Row>

            <Row>
              <Col>
                <span className="orderDetailTitle">Status:</span>
                <span className={order.status}>
                  {" "}
                  {order.status ? order.status : "Completed"}
                </span>
              </Col>
            </Row>
            <Row>
              <Col>
                <span className="orderDetailTitle">Price: </span>
                <span>
                  {order.priceEn > 0
                    ? `${order.priceEn} USD`
                    : `${order.priceRu} RUB`}
                </span>
              </Col>
            </Row>
            <Row>
              <Col>
                <span className="orderDetailTitle">Date Ordered: </span>
                <span>{dateFormat(order.dateOrdered)}</span>
              </Col>
            </Row>
            <Row>
              <Col>
                <span className="orderDetailTitle">User ID: </span>
                <Link to={`user/${order.userId}`} className="orderId">
                  <span>{order.userId}</span>
                </Link>
              </Col>
            </Row>
            {order.type === "tg_spam" ||
              order.type === "tg_groupSpam" ||
              order.type === "tg_inviting"}
            <Row>
              <Col className="detailsButtonCol">
                <Button
                  className="orderDetailsButton"
                  onClick={() => handleShow()}
                >
                  Details
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      </Col>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        className="detailsModal"
      >
        <DetailsModal
          order={order}
          language="EN"
          handleClose={handleClose}
          dateFormat={dateFormat}
        />
      </Modal>
    </>
  );
};

export default Order;
