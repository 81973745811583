import Cookies from "js-cookie";
import { Container, Nav, Navbar } from "react-bootstrap";
import "./Header.css";

const Header = ({ unpaidCount, loading }) => {
  const role = Cookies.get("role");

  const logout = () => {
    Cookies.remove("token");
  };

  return (
    <Navbar expand="lg" className="adminNavbar">
      <Container fluid>
        <Navbar.Brand href="/orders" className="navbarLayout">
          <img
            src={process.env.PUBLIC_URL + "/brand_logo.png"}
            className="navbarLogo"
            alt="logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <div>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/">Pay Out</Nav.Link>
              {role === "ceo" && (
                <>
                  <Nav.Link href="/orders">Orders</Nav.Link>
                  <Nav.Link
                    href="/pay"
                    data-badge={unpaidCount}
                    className={!loading && unpaidCount > 0 && "badger"}
                  >
                    Unpaid Orders
                  </Nav.Link>
                  <Nav.Link href="/expenses">Expenses</Nav.Link>
                </>
              )}
              <Nav.Link href="/logout" onClick={() => logout()}>
                Logout
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Container>
    </Navbar>
  );
};

export default Header;
