export const dateFormat = (date) => {
  const orderDate = new Date(date);
  let resultStr = `${
    orderDate.getMonth() + 1
  }/${orderDate.getDate()}/${orderDate.getFullYear()} ${orderDate.getHours()}:${orderDate
    .getMinutes()
    .toString()
    .padStart(2, "0")}`;
  return resultStr;
};

export const formDateFormat = (date) => {
  const orderDate = new Date(date);
  let resultStr = `${orderDate.getFullYear()}-${(
    "0" +
    (orderDate.getMonth() + 1)
  ).slice(-2)}-${("0" + orderDate.getDate()).slice(-2)}`;
  return resultStr;
};
