import Cookies from "js-cookie";
import { useState } from "react";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import "./PayOut.css";
import { dateFormat } from "../../dateFormat";

const PayOut = () => {
  const [loading, setLoading] = useState(false);
  const [date1, setDate1] = useState("");
  const [date2, setDate2] = useState("");
  const [amount, setAmount] = useState(null);
  const [user, setUser] = useState("");
  const [referralOrdersData, setReferralOrdersData] = useState(null);
  const isCeo = Cookies.get("role") === "ceo";
  const login = Cookies.get("login");

  const handlePayOut = () => {
    setLoading(true);
    fetch(
      `https://api.pavelandco.com/api/admin/payout/${
        process.env.REACT_APP_ADMIN_HASH
      }?date1=${date1}&date2=${date2}&user=${isCeo ? user : login}`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        setReferralOrdersData(data);
        calculateAmount(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePayOutMonth = (e) => {
    e.preventDefault();
    setLoading(true);

    let y = parseInt(e.target.year.value),
      m = parseInt(e.target.month.value);
    let firstDay = new Date(y, m - 1, 1);
    let lastDay = new Date(y, m, 0);

    fetch(
      `https://api.pavelandco.com/api/admin/payout/${
        process.env.REACT_APP_ADMIN_HASH
      }?date1=${firstDay}&date2=${lastDay}&user=${isCeo ? user : login}`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        setReferralOrdersData(data);
        calculateAmount(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const calculateAmount = (orders) => {
    setAmount(orders.reduce((sum, order) => sum + order.priceRu, 0) * 0.2);
  };

  return (
    <>
      <Container className="mt-4">
        <Row>
          <Col xs={12} md={4}>
            <Row>
              <Col>
                <h2>Payout</h2>
              </Col>
            </Row>
            <Row>
              <Col>
                <FloatingLabel className="mb-3" label="From:">
                  <Form.Control
                    type="date"
                    name="date1"
                    value={date1}
                    onChange={(e) => setDate1(e.target.value)}
                  />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel label="To:">
                  <Form.Control
                    type="date"
                    name="date2"
                    value={date2}
                    onChange={(e) => setDate2(e.target.value)}
                  />
                </FloatingLabel>
              </Col>
            </Row>
            {isCeo && (
              <Row>
                <Col>
                  <FloatingLabel label="User">
                    <Form.Control
                      type="text"
                      name="user"
                      value={user}
                      onChange={(e) => setUser(e.target.value)}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
            )}
            <Row>
              <Col className="d-flex justify-content-center">
                <Button className="calculateButton mt-2" onClick={handlePayOut}>
                  Calculate
                </Button>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <h2>Or</h2>
              </Col>
            </Row>
            <Form onSubmit={(e) => handlePayOutMonth(e)}>
              <Row>
                <Col>
                  <FloatingLabel controlId="month" label="Month">
                    <Form.Select defaultValue={new Date().getMonth() + 1}>
                      <option value="1">January</option>
                      <option value="2">February</option>
                      <option value="3">March</option>
                      <option value="4">April</option>
                      <option value="5">May</option>
                      <option value="6">June</option>
                      <option value="7">July</option>
                      <option value="8">August</option>
                      <option value="9">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                    </Form.Select>
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FloatingLabel controlId="year" label="Year" className="mt-2">
                    <Form.Control
                      min="2021"
                      type="number"
                      defaultValue={new Date().getFullYear()}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              {isCeo && (
                <Row>
                  <Col>
                    <FloatingLabel label="User" className="mt-2">
                      <Form.Control
                        type="text"
                        name="user"
                        value={user}
                        onChange={(e) => setUser(e.target.value)}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
              )}
              <Row>
                <Col className="d-flex justify-content-center">
                  <Button type="submit" className="calculateButton mt-2">
                    Calculate
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col xs={12} md={8} className="payOutCol">
            {loading && (
              <>
                Loading <Spinner size="sm" animation="border" variant="dark" />
              </>
            )}
            {!loading && amount !== null && (
              <>
                <Row>
                  <Col className="mb-4">
                    <h3>
                      Total Amount: <i>{amount.toFixed(2)} RUB</i>
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col></Col>
                </Row>

                <Row>
                  <Col>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Order ID</th>
                          <th>Date</th>
                          <th>Order Type</th>
                          <th>User ID</th>
                          <th>Profit</th>
                        </tr>
                      </thead>
                      <tbody>
                        {referralOrdersData.map((order, index) => {
                          return (
                            <tr key={order._id}>
                              <td>{index + 1}</td>
                              <td>{order._id}</td>
                              <td>{dateFormat(order.dateOrdered)}</td>
                              <td>{order.type}</td>
                              <td>{order.userId}</td>
                              <td>{order.priceRu * 0.2}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default PayOut;
