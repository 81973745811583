import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
} from "react-bootstrap";
import { formDateFormat } from "../../dateFormat";
import { useHistory } from "react-router-dom";

const UserInfo = ({ props }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [formData, updateFormData] = useState({});
  const [formOrderData, setFormOrderData] = useState([]);

  const history = useHistory();

  const token = Cookies.get("token");
  //   const ignoreFields = ["__v", "_id", "dateOrdered", "leadSource", "priceEn"];

  useEffect(() => {
    fetch(
      `https://api.pavelandco.com/api/admin/user/${process.env.REACT_APP_ADMIN_HASH}?id=${props.match.params.id}`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `jwt ${token}`,
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          setUser(response.status);
          return response.json();
        }
        setUser(response.data);
        throw response;
      })
      .then((userData) => {
        setUser(userData);
        updateFormData(userData);
        setFormOrderData(userData.orders);
        console.log(userData);
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        setLoading(false);
      });
  }, [props.match.params.id, token]);

  const defineInputType = (value, name) => {
    switch (typeof value) {
      case "string":
        if (name === "dateOrdered") {
          return "date";
        }
        return "text";
      case "number":
        return "number";
      default:
        return "text";
    }
  };

  const checkDate = (value, name) => {
    if (name === "dateOrdered") {
      return formDateFormat(value);
    } else return value;
  };

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleOrderChange = (e) => {
    let tmpOrderData = [...formOrderData];
    tmpOrderData[e.target.name] = e.target.value;
    setFormOrderData(tmpOrderData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    formData.orders = formOrderData;
    fetch(
      `https://api.pavelandco.com/api/admin/user/update/${process.env.REACT_APP_ADMIN_HASH}`,
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `jwt ${token}`,
        },
        body: JSON.stringify(formData),
      }
    )
      .then((response) => {
        if (response.ok) {
          setUser(response.status);
          return response.json();
        }
        setUser(response.data);
        throw response;
      })
      .then((orderData) => {
        console.log(orderData);
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        setLoading(false);
        history.go();
      });
  };

  if (loading) {
    return <>Loading</>;
  }

  return (
    <>
      <Container className="orderInfoContainer">
        <h1>{user._id}</h1>
        <Form onSubmit={handleSubmit}>
          {Object.entries(user)
            .filter(
              ([key, value]) => typeof value !== "object"
              //  && !ignoreFields.includes(key)
            )
            .map(([key, value]) => {
              return (
                <Row key={key}>
                  <Col>
                    <FloatingLabel
                      controlId="floatingInput"
                      label={key}
                      className="mb-3"
                    >
                      <Form.Control
                        name={key}
                        onChange={handleChange}
                        type={defineInputType(value, key)}
                        defaultValue={checkDate(value, key)}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
              );
            })}
          {Object.entries(user.orders).map(([key, value]) => {
            return (
              <Row key={key}>
                <Col>
                  <FloatingLabel
                    controlId="floatingInput"
                    label={key}
                    className="mb-3"
                  >
                    <Form.Control
                      name={key}
                      onChange={handleOrderChange}
                      type={defineInputType(value, key)}
                      defaultValue={checkDate(value, key)}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
            );
          })}
          <Row>
            <Col>
              <Button type="submit">Submit</Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default UserInfo;
